import React from 'react';
import loadable from '@loadable/component';

const TwoColumns = loadable(() => import('~/components/organisms/TwoColumns.js'));
const Testimonial = loadable(() => import('~/components/organisms/Testimonial.js'));
const Features = loadable(() => import('~/components/organisms/Features.js'));
const Usps = loadable(() => import('~/components/organisms/Usps.js'));
const Intro = loadable(() => import('~/components/organisms/Intro.js'));
const Reviews = loadable(() => import('~/components/organisms/Reviews.js'));
const FwImage = loadable(() => import('~/components/organisms/FwImage.js'));
const LatestResources = loadable(() => import('~/components/organisms/LatestResources.js'));
const Faqs = loadable(() => import('~/components/organisms/Faqs.js'));
const Press = loadable(() => import('~/components/organisms/Press.js'));
const Team = loadable(() => import('~/components/organisms/Team.js'));
const Awards = loadable(() => import('~/components/organisms/Awards.js'));
const TwoColumnImageFeatures = loadable(() => import('~/components/organisms/TwoColumnImageFeatures.js'));
const TestimonialsSlider = loadable(() => import('~/components/organisms/TestimonialsSlider.js'));
const TwoColumnFeatures = loadable(() => import('~/components/organisms/TwoColumnFeatures.js'));
const Values = loadable(() => import('~/components/organisms/Values.js'));
const Cta = loadable(() => import('~/components/organisms/Cta.js'));
const TextImageGrid = loadable(() => import('~/components/organisms/TextImageGrid.js'));
const Logos = loadable(() => import('~/components/organisms/Logos.js'));
const Quote = loadable(() => import('~/components/organisms/Quote.js'));
const HeroPricing = loadable(() => import('~/components/organisms/HeroPricing.js'));
const HeroPricing4 = loadable(() => import('~/components/organisms/HeroPricing4.js'));
const PricingWidget = loadable(() => import('~/components/organisms/PricingWidget.js'));
const HeroSlider = loadable(() => import('~/components/organisms/HeroSlider.js'));
const IntroImagesParallax = loadable(() => import('~/components/organisms/IntroImagesParallax.js'));
const Careers = loadable(() => import('~/components/organisms/Careers.js'));
const IntroImages = loadable(() => import('~/components/organisms/IntroImages.js'));
const HeroTwoColumn = loadable(() => import('~/components/organisms/HeroTwoColumn.js'));
const ImagesSlider = loadable(() => import('~/components/organisms/ImagesSlider.js'));
const ImageUsps = loadable(() => import('~/components/organisms/ImageUsps.js'));
const HeroAbout = loadable(() => import('~/components/organisms/HeroAbout.js'));
const FeaturedStory = loadable(() => import('~/components/organisms/FeaturedStory.js'));
const FeaturedStories = loadable(() => import('~/components/organisms/FeaturedStories.js'));
const Newsletter = loadable(() => import('~/components/organisms/Newsletter.js'));
const TextCta = loadable(() => import('~/components/organisms/TextCta.js'));
const Download = loadable(() => import('~/components/organisms/Download.js'));
const Text = loadable(() => import('~/components/organisms/Text.js'));
const Neighbourhood = loadable(() => import('./organisms/Neighbourhood'));
const CertificateTemplate = loadable(() => import('./organisms/CertificateTemplate'));
const Hero = loadable(() => import('~/components/organisms/Hero.js'));
const HeroReferral = loadable(() => import('~/components/organisms/HeroReferral.js'));
const Buildings = loadable(() => import('~/components/organisms/Buildings.js')); // Add this line for Buildings component
const LongLetFees = loadable(() => import('~/components/organisms/LongLetFees.js')); // Add this line for LongLetFees component

/**
 * Mapping the fieldGroupName(s) to our components
 */
const layouts = {
  page_Page_Page_Content_Usps: Usps,
  page_Page_Page_Content_TwoColumns: TwoColumns,
  page_Page_Page_Content_Testimonial: Testimonial,
  page_Page_Page_Content_Features: Features,
  page_Page_Page_Content_Intro: Intro,
  page_Page_Page_Content_Reviews: Reviews,
  page_Page_Page_Content_LatestResources: LatestResources,
  page_Page_Page_Content_FullWidthImage: FwImage,
  page_Page_Page_Content_Faqs: Faqs,
  page_Pricing_Pricing_Content_Faqs: Faqs,
  page_Formpricing1_Formpricing1_Content_Faqs: Faqs,
  page_Formpricing3_Formpricing3_Content_Faqs: Faqs,
  page_Page_Page_Content_Hero: Hero,
  page_Page_Page_Content_Press: Press,
  page_Page_Page_Content_Team: Team,
  page_Page_Page_Content_Awards: Awards,
  page_Page_Page_Content_TwoColumnFeaturesWImage: TwoColumnImageFeatures,
  page_Page_Page_Content_TestimonialsSlider: TestimonialsSlider,
  page_Page_Page_Content_TwoColumnFeatures: TwoColumnFeatures,
  page_Page_Page_Content_Values: Values,
  page_Page_Page_Content_Cta: Cta,
  page_Page_Page_Content_TextImageGrid: TextImageGrid,
  page_Page_Page_Content_Logos: Logos,
  page_Page_Page_Content_Quote: Quote,
  page_Page_Page_Content_HeroPricing: HeroPricing,
  page_Page_Page_Content_Neighbourhood: Neighbourhood,
  page_Page_Page_Content_HeroPricing2: HeroPricing4,
  page_Page_Page_Content_PricingWidget: PricingWidget,
  page_Page_Page_Content_HeroSlider: HeroSlider,
  page_Page_Page_Content_IntroWImagesParallaxing: IntroImagesParallax,
  page_Page_Page_Content_Careers: Careers,
  page_Page_Page_Content_IntroImages: IntroImages,
  page_Page_Page_Content_HeroTwoColumn: HeroTwoColumn,
  page_Page_Page_Content_ImageSlider: ImagesSlider,
  page_Page_Page_Content_ImageUsps: ImageUsps,
  page_Page_Page_Content_HeroAbout: HeroAbout,
  page_Partnerstories_Partners_Content_FeaturedStory: FeaturedStory,
  page_Page_Page_Content_PartnerStory: FeaturedStory,
  page_Partnerstories_Partners_Content_Cta: Cta,
  page_Partnerstories_Partners_Content_Quote: Quote,
  page_Partnerstories_Partners_Content_FeaturedStories: FeaturedStories,
  page_Page_Page_Content_TextCta: TextCta,
  page_Page_Page_Content_Newsletter: Newsletter,
  page_Page_Page_Content_Certificate: CertificateTemplate,
  post_Resourcepostitem_ResourcePostItem_Content_Download: Download,
  post_Resourcepostitem_ResourcePostItem_Content_Text: Text,
  page_Page_Page_Content_HeroReferral: HeroReferral,
  page_Page_Page_Content_Buildings: Buildings, // Add this line for Buildings component
  page_Page_Page_Content_Longletfees: LongLetFees, // Add this line for LongLetFees component
};

const FlexibleContent = ({ content }) => {

  if (!content)
    return null

  return content.map(
    ({ fieldGroupName, ...layoutProps }, index) => {
      /**
       * Default component
       */
      const Default = () => (
        <div>
          In FlexibleContent the mapping of this component is missing: {fieldGroupName}
        </div>
      );

      /**
       * If layout type is not existing in our mapping, it shows our Default instead.
       */
      const ComponentTag = layouts[fieldGroupName]
        ? layouts[fieldGroupName]
        : Default;

      return React.createElement(ComponentTag, {
        key: fieldGroupName + index,
        index: index,
        ...layoutProps
      });
    }
  );
};

export default FlexibleContent;
